/* Tailwind Base Imports */
@tailwind base;
@tailwind components;
@tailwind utilities;

/* Ant Design Overrides */

/* Form Item */
.ant-form-item {
  @apply mb-6;
}

/* Primary Button */
.ant-btn-primary {
  color: #fff;
  background-color: #1677ff;
  box-shadow: 0 2px 0 rgba(5, 145, 255, 0.1);
}

.ant-btn-icon {
  @apply flex justify-center items-center !important;
}

/* Menu Customizations */
.ant-menu-light.ant-menu-root.ant-menu-vertical {
  @apply border-none;
}

.ant-menu-submenu-title {
  @apply p-0 rounded pl-[11px] pt-[2px] text-gray-500 hover:text-gray-700 transition-all ease-in duration-200 !important;
}

.ant-menu-item {
  @apply p-0 rounded pl-[11px] pt-[2px] text-gray-500 hover:text-gray-700 transition-all ease-in duration-200 !important;
}

.ant-menu-item-icon {
  @apply w-[18px] h-[18px] !important;
}

.ant-menu-item.ant-menu-item-selected {
  @apply bg-blue-200 text-gray-700 !important;
}

/* Select Customizations */
.ant-select-item.ant-select-item-option {
  @apply my-1 font-normal !important;
}

.ant-select-item.ant-select-item-option.ant-select-item-option-selected {
  @apply bg-gray-100 font-normal text-gray-500 !important;
}

/* Form Layout */
.ant-form-vertical .ant-form-item-label {
  @apply pb-1;
}

/* Drawer Customizations */
.ant-drawer .ant-drawer-body {
  @apply p-0;
}

/* DRAWER PADDING FOR FORM */
.ant-drawer .ant-form.ant-form-vertical {
  @apply p-3 !important;
}

/* ALIGN RING TABS */
.ant-tabs-tab.ant-tabs-tab-active {
  @apply px-[5px] !important;
}
.ant-tabs-tab {
  @apply px-[5px] m-0 !important;
}

/* D3 Table Styles */
.d3-table {
  @apply flex flex-col w-full h-full pb-1 pr-1 overflow-hidden bg-transparent shadow select-none !important;
}

.d3-table .ant-btn {
  @apply flex items-center justify-center disabled:flex disabled:items-center disabled:justify-center;
}

.d3-table .ant-table-wrapper {
  @apply h-full bg-white rounded shadow;
}

.d3-table .ant-table-title,
.ant-table-footer {
  @apply rounded-t rounded-b !important;
}

.d3-table .ant-spin-nested-loading,
.ant-spin-container {
  @apply h-full;
}

.d3-table .ant-table {
  @apply flex flex-col h-full max-h-full bg-transparent;
}

.d3-table .ant-table-title,
.ant-table-footer {
  @apply flex items-center justify-between bg-white h-14;
}

.d3-table .ant-table-container {
  @apply overflow-y-auto rounded grow [&::-webkit-scrollbar]:w-2
  [&::-webkit-scrollbar]:h-2
  [&::-webkit-scrollbar-track]:rounded-none
  [&::-webkit-scrollbar-track]:bg-gray-100
  [&::-webkit-scrollbar-thumb]:rounded
  [&::-webkit-scrollbar-thumb]:bg-gray-300
  dark:[&::-webkit-scrollbar-track]:bg-gray-300
  dark:[&::-webkit-scrollbar-thumb]:bg-neutral-500;
}

.d3-table .ant-table-thead {
  @apply sticky top-0 z-10 flex-initial;
}

.d3-table .ant-table-tbody {
  @apply h-full;
}

.d3-table .ant-table-footer {
  border-top: 1px solid #f3f4f6 !important;
  @apply flex items-center justify-between bg-white h-14;
}

/* Service Registration Table */
.register-service.ant-form-item {
  @apply m-0;
}

.register-service.ant-table-cell {
  @apply px-2 py-1 !important;
}

/* Drawer Custom Scrollbar */
.ant-drawer-body {
  @apply [&::-webkit-scrollbar]:w-2
  [&::-webkit-scrollbar]:h-2
  [&::-webkit-scrollbar-track]:rounded-none
  [&::-webkit-scrollbar-track]:bg-gray-100
  [&::-webkit-scrollbar-thumb]:rounded
  [&::-webkit-scrollbar-thumb]:bg-gray-300
  dark:[&::-webkit-scrollbar-track]:bg-gray-300
  dark:[&::-webkit-scrollbar-thumb]:bg-neutral-500;
}

/* Table Shelf Header fixed top*/
.d3-table-shelf .ant-table-thead {
  @apply sticky top-0 z-10 flex-initial;
}

/* Tabs scroll in body */

.ant-tabs-content.ant-tabs-content-left {
  @apply flex flex-col h-full overflow-hidden !important;
}

.ant-tabs-tabpane.ant-tabs-tabpane-active {
  @apply flex flex-col h-full overflow-hidden !important;
}

/* estilização scroll submenu  */
.ant-menu.ant-menu-sub.ant-menu-vertical {
  @apply [&::-webkit-scrollbar]:w-2
  [&::-webkit-scrollbar]:h-2
  [&::-webkit-scrollbar-track]:rounded-none
  [&::-webkit-scrollbar-track]:bg-gray-100
  [&::-webkit-scrollbar-thumb]:rounded
  [&::-webkit-scrollbar-thumb]:bg-gray-300
  dark:[&::-webkit-scrollbar-track]:bg-gray-300
  dark:[&::-webkit-scrollbar-thumb]:bg-neutral-500 !important;
}

/* estilização scroll lista de documentos  */

.d3-list-doc .ant-card-actions {
  @apply px-2  bg-transparent border-t-gray-200  !important;
}

.d3-list-doc .ant-card-head {
  @apply m-0 px-2 h-[48px] border-b-gray-200  bg-transparent !important;
}

/*removendo padding do drawer para visualizar pdf*/

.d3-pdf-view .ant-drawer-body {
  @apply p-0  !important;
}
.rpv-core__inner-pages.rpv-core__inner-pages--vertical {
  @apply [&::-webkit-scrollbar]:w-2
  [&::-webkit-scrollbar]:h-2
  [&::-webkit-scrollbar-track]:rounded-none
  [&::-webkit-scrollbar-track]:bg-gray-100
  [&::-webkit-scrollbar-thumb]:rounded
  [&::-webkit-scrollbar-thumb]:bg-gray-300
  dark:[&::-webkit-scrollbar-track]:bg-gray-300
  dark:[&::-webkit-scrollbar-thumb]:bg-neutral-500 !important;
}

/*remove setas do inputnumber*/
.d3-no-arrows .ant-input-number-handler-wrap {
  @apply hidden !important;
}

/*upload table*/

.d3-table-h-full {
  @apply h-full overflow-hidden !important;
}

.d3-table-h-full .ant-table-wrapper {
  @apply h-full overflow-hidden !important;
}

.d3-table-h-full .ant-spin-nested-loading,
.ant-spin-container {
  @apply h-full  overflow-hidden !important;
}

.d3-table-h-full .ant-table {
  @apply flex flex-col h-full overflow-hidden max-h-full bg-transparent  !important;
}

.d3-table-h-full .ant-table-title {
  @apply flex items-center justify-between bg-white min-h-12 h-12 !important;
}

.d3-table-h-full .ant-table-container {
  @apply overflow-y-auto  border h-full [&::-webkit-scrollbar]:w-2
  [&::-webkit-scrollbar]:h-2
  [&::-webkit-scrollbar-track]:rounded-none
  [&::-webkit-scrollbar-track]:bg-gray-100
  [&::-webkit-scrollbar-thumb]:rounded
  [&::-webkit-scrollbar-thumb]:bg-gray-300
  dark:[&::-webkit-scrollbar-track]:bg-gray-300
  dark:[&::-webkit-scrollbar-thumb]:bg-neutral-500 !important;
}

.d3-table-h-full .ant-table-thead {
  @apply sticky top-0 z-10 flex-initial;
}

.d3-table-h-full .ant-table-tbody {
  @apply h-full overflow-hidden  !important;
}

.d3-table-h-full .ant-table-footer {
  @apply flex items-center bg-white min-h-10 h-10 border-t-0 rounded-t-none !important;
}

.d3-table-h-full .d3-is-duplicate {
  @apply bg-red-100 !important;
}

/* d3-upload-dragger */
.d3-upload-dragger .ant-upload-drag-container {
  @apply bg-blue-100 m-0 p-0 rounded-lg !important;
}
.d3-upload-dragger .ant-upload .ant-upload-btn {
  @apply m-0 p-[1px]  !important;
}

/* FORMULÁRIO DE EXIBIÇÃO REMOVER MARGIM E PADDING */
.form-view .ant-form-item {
  @apply m-0 p-2  !important;
}

/* SPACE SKELETON IMAGE */
.space .ant-skeleton-image {
  @apply h-16 w-16 !important;
}

.shadow-b {
  --tw-shadow: 0 2px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-shadow);
}

.shadow-t {
  --tw-shadow: 0 -2px 4px rgba(0, 0, 0, 0.1);
  --tw-shadow-colored: 0 -2px 4px var(--tw-shadow-color);
  box-shadow: var(--tw-shadow);
}

/* MENU SPAM MARGIN RIGHT */
.ant-menu-title-content {
  @apply mr-2 !important;
}

/* UPLOAD POPOVER */
.d3-list-upload {
  @apply min-w-[500px] max-h-[250px] overflow-hidden select-none !important;
}

.d3-list-upload .ant-spin-nested-loading {
  @apply min-w-[500px] max-h-[250px]  overflow-hidden !important;
}

.d3-list-upload .ant-spin-container {
  @apply min-w-[500px] max-h-[250px]  overflow-y-auto [&::-webkit-scrollbar]:w-2
  [&::-webkit-scrollbar]:h-2
  [&::-webkit-scrollbar-track]:rounded-none
  [&::-webkit-scrollbar-track]:bg-gray-100
  [&::-webkit-scrollbar-thumb]:rounded
  [&::-webkit-scrollbar-thumb]:bg-gray-300
  dark:[&::-webkit-scrollbar-track]:bg-gray-300
  dark:[&::-webkit-scrollbar-thumb]:bg-neutral-500  !important;
}

.d3-list-upload .ant-list-item {
  @apply px-2 !important;
}

.custom-icon {
  animation: none !important; /* Override any animation */
}

.h-footer .ant-table-footer {
  @apply min-h-12 !important;
}

/* FORM ITEM PARA TABLE HFULL SCROLL */

.form-item-for-table {
  @apply h-full overflow-hidden !important;
}

.form-item-for-table .ant-row.ant-form-item-row,
.form-item-for-table .ant-form-item-control-input-content,
.form-item-for-table .ant-form-item-control-input {
  @apply h-full overflow-hidden !important;
}

/* tabela responsivel */
.responsive-table,
.responsive-table .ant-spin-nested-loading,
.responsive-table .ant-spin-container {
  @apply flex overflow-hidden h-full  w-full !important;
}

.responsive-table .ant-table.ant-table-small.ant-table-bordered {
  @apply flex flex-col overflow-hidden h-full w-full !important;
}

.responsive-table .ant-table-container {
  @apply flex overflow-x-auto overflow-y-auto w-full  h-full 
[&::-webkit-scrollbar]:w-2
[&::-webkit-scrollbar]:h-2
  [&::-webkit-scrollbar-track]:rounded-none
  [&::-webkit-scrollbar-track]:bg-gray-100
  [&::-webkit-scrollbar-thumb]:rounded
  [&::-webkit-scrollbar-thumb]:bg-gray-300
  dark:[&::-webkit-scrollbar-track]:bg-gray-300
  dark:[&::-webkit-scrollbar-thumb]:bg-neutral-500
  !important;
}

.responsive-table .ant-table-thead {
  @apply sticky top-0 z-10 flex-initial;
}

.responsive-table .ant-table-content {
  @apply w-full !important;
}

.responsive-table .ant-table-title {
  @apply flex  h-12 !important;
}

.responsive-table .ant-table-footer {
  @apply flex  shadow-none h-12 rounded-none !important;
  border-top: 1px solid #f3f4f6 !important;
}

.responsive-table .ant-table-cell {
  @apply select-none !important;
  white-space: nowrap !important;
}
.responsive-table .ant-table {
  table-layout: auto !important;
}
.responsive-table .ant-table-thead > tr > th {
  white-space: nowrap !important;
}
.responsive-table .ant-table-tbody > tr > td {
  white-space: nowrap !important;
}

/* icon clear select */
.ant-select-clear {
  @apply text-red-400 !important;
}

.ant-select-arrow-loading {
  @apply text-blue-500 !important;
}
.ant-select-arrow {
  @apply text-blue-500 !important;
}

.ant-btn {
  @apply flex justify-center items-center !important;
}

.ant-popconfirm-buttons {
  @apply flex justify-end items-center gap-1 !important;
}

.custom-scroll {
  @apply [&::-webkit-scrollbar]:w-2
  [&::-webkit-scrollbar]:h-2
  [&::-webkit-scrollbar-track]:rounded-none
  [&::-webkit-scrollbar-track]:bg-gray-100
  [&::-webkit-scrollbar-thumb]:rounded
  [&::-webkit-scrollbar-thumb]:bg-gray-300
  dark:[&::-webkit-scrollbar-track]:bg-gray-300
  dark:[&::-webkit-scrollbar-thumb]:bg-neutral-500 !important;
}

/*Cursor Disable Important */
.cursor-disable-important {
  @apply cursor-not-allowed !important;
}

/*Ajuste botão page select customer */
.ant-result-extra {
  @apply flex justify-center items-center !important;
}

/*Ajuste modal footer align buttons */
.ant-modal-footer {
  @apply flex gap-1 justify-end items-center !important;
}

/*Tabs Table Border Right */
.tabs-table .ant-table-container {
  @apply border-r-[1px] border-b-[1px] !important;
}

/*Tabs Table Radius Bottom Footer */
.tabs-table .ant-table-footer {
  @apply rounded-b-lg !important;
}

/*Tabs Table Title Height Max Content */
.tabs-table .ant-table-title {
  @apply min-h-[90px] !important;
}

/*Tabs Table Expanded Remove M and P */
.expanded-table .ant-table.ant-table-bordered {
  @apply m-0 p-0 !important;
}

.tab-filter-work .ant-tabs .ant-tabs-tab {
  @apply py-2 !important;
}

.rc-virtual-list-holder {
  @apply [&::-webkit-scrollbar]:w-2
  [&::-webkit-scrollbar]:h-2
  [&::-webkit-scrollbar-track]:rounded-none
  [&::-webkit-scrollbar-track]:bg-gray-100
  [&::-webkit-scrollbar-thumb]:rounded
  [&::-webkit-scrollbar-thumb]:bg-gray-300
  dark:[&::-webkit-scrollbar-track]:bg-gray-300
  dark:[&::-webkit-scrollbar-thumb]:bg-neutral-500 !important;
}

.ant-collapse-content.ant-collapse-content-active {
  @apply overflow-hidden !important;
}

.ant-collapse-content-box {
  @apply h-full overflow-hidden !important;
}

/*View documents */
#proxy-renderer {
  @apply flex h-full !important;
}

/*Scroll Select personalizado */
.rc-virtual-list-holder {
  @apply cursor-pointer
  [&::-webkit-scrollbar]:h-[6px]
  [&::-webkit-scrollbar]:w-[6px]
  [&::-webkit-scrollbar-track]:rounded-md
  [&::-webkit-scrollbar-track]:bg-transparent
  [&::-webkit-scrollbar-thumb]:rounded-md
  [&::-webkit-scrollbar-thumb]:bg-blue-300
  dark:[&::-webkit-scrollbar-track]:bg-gray-300
  dark:[&::-webkit-scrollbar-thumb]:bg-blue-500
  !important;
}
/*textarea custom-scroll */
.ant-input {
  @apply [&::-webkit-scrollbar]:w-2
  [&::-webkit-scrollbar]:h-2
  [&::-webkit-scrollbar-track]:rounded-none
  [&::-webkit-scrollbar-track]:bg-gray-100
  [&::-webkit-scrollbar-thumb]:rounded
  [&::-webkit-scrollbar-thumb]:bg-gray-300
  dark:[&::-webkit-scrollbar-track]:bg-gray-300
  dark:[&::-webkit-scrollbar-thumb]:bg-neutral-500 !important;
}

/*tab button color text and icon */
.ant-tabs-tab-btn {
  @apply text-gray-600;
}

/* Estilo para todas as submenus */
.ant-menu.ant-menu-sub.ant-menu-inline {
  @apply bg-gray-100 mx-[5px] rounded !important;
}

/* Estilo apenas para a última sub-menu */
.ant-menu .ant-menu-sub .ant-menu-inline:last-child {
  @apply bg-gray-200 !important;
}

/* Versão File List.Item removendo  padding-inline-start para zero*/
.version {
  @apply ps-0 pe-0 !important;
}

/* Table virtual */
.table-virtual-documents,
.table-virtual-documents .ant-spin-nested-loading,
.table-virtual-documents .ant-table,
.table-virtual-documents .ant-table-container {
  @apply flex h-full flex-col grow !important;
}

/* Tabs Vertical padding right  */
.ant-tabs-nav-list {
  @apply pr-3 !important;
}

/*Scroll Menu */
.menu-scroll {
  @apply [&::-webkit-scrollbar]:w-1
  [&::-webkit-scrollbar]:h-1
  [&::-webkit-scrollbar-track]:rounded-none
  [&::-webkit-scrollbar-track]:bg-gray-100
  [&::-webkit-scrollbar-thumb]:rounded
  [&::-webkit-scrollbar-thumb]:bg-gray-300
  dark:[&::-webkit-scrollbar-track]:bg-gray-300
  dark:[&::-webkit-scrollbar-thumb]:bg-neutral-500 !important;
}

/*Tree Customer Template */
.customer-template .ant-tree-treenode {
  @apply px-1 py-[2px] text-base font-semibold text-gray-600;
}

.customer-template .ant-tree-node-content-wrapper {
  @apply flex items-center w-full;
}

.customer-template .ant-tree-title{
  @apply w-full;
}

.customer-template .ant-tree-draggable-icon{
  @apply flex items-center justify-center  h-[30px];
}

.customer-template .ant-tree-switcher {
  @apply  flex justify-center items-center
}